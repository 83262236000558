// 导入封装好的网络请求类工具
import Network from './netWork'

// 封装各种接口请求
// export const 接口名 = () => Network.get('/路由',参数对象);
export const getNewsList = (page = 1, limit = 5) => Network.get('/44EA43C11BE942DDA2FAD4A136FFD3D4/list', { page, limit, 'scope.NEWS_STATE': '01', order_mode: 'desc', order_field: 'NEWS_TIME' })

export const updateNews = (id, LOOK_NUM) => Network.post('/44EA43C11BE942DDA2FAD4A136FFD3D4/update', { 'bean.ID': id, 'bean.NEWS_CLICK': LOOK_NUM })

export const findNews = (id) => Network.get('/44EA43C11BE942DDA2FAD4A136FFD3D4/find', { 'scope.ID': id })

export const submitForm = (NAME, MOBILE, DEPT) => Network.post('/B1C0E153B3C14D459FBD88B9944DD97B/save', {
  'bean.NAME': NAME,
  'bean.MOBILE': MOBILE,
  'bean.DEPT': DEPT
})

// 获取代码表
export const getTree = key => Network.get('/FM_CODE_DEFINE/getTree?', key)
