<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="新闻动态" @moTitltFn="navPanel"></MoTitle>
    <div class="newContainer">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;">新闻动态</h1>
          <p style="color:#fff;margin: 1rem 0;font-size: 0.875rem;line-height: 1.375rem;">专业源于专注，专注产生价值 </p>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>

      <div class="contentBgColor center">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :immediate-check="false"
            >
            <div v-for="(item,index) in journalism" :key="index" class="newsItem" @click="handleClick(item)">
              <div style="min-width:40%;max-width:40%">
                <img :src="item.src" alt="" style="width:100%;height:100%">
              </div>
              <div class="newContent">
                  <div class="newsItemTitle">{{item.title}}</div>
                  <span>{{item.time}}</span>
              </div>
            </div>
          </van-list>
      </div>
      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import { getNewsList, updateNews } from '@/require/index.js'
export default {
  name: 'moNews',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      journalism: [],
      list: [],
      loading: false,
      finished: false,
      total: 0,
      page: 1
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    // 获取列表
    getNewsList (page) {
      getNewsList(page, 10).then(res => {
        this.journalism = []
        this.total = res.total

        if (res.entitys) {
          res.entitys.forEach(item => {
            const src = item.NEWS_PICTURE ? (this.baseUrl + '/download?id=' + item.NEWS_PICTURE) : require('@/assets/journalism/news1.png')
            const obj = { title: item.NEWS_TITLE, desc: item.NEWS_INTRODUCTION, see: item.NEWS_CLICK, time: item.NEWS_TIME, src, id: item.ID, content: item.NEWS_CONTENT }
            this.journalism.push(obj)
          })
        }
      })
    },
    // 加载更多
    onLoad () {
      if (this.total <= this.page * 10) {
        this.finished = true
      } else {
        this.page++
        this.getNewsList(this.page)
        this.loading = false
      }
    },
    // 点击跳转
    handleClick (item) {
      const lookNum = item.see ? (Number(item.see) + 1) : 1
      updateNews(item.id, lookNum).then(() => {
        this.$router.push({ path: '/moNews/moNewText', query: { id: item.id } })
      })
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/news')
      }
    }

  },
  created () {
    this.journalism = []

    this.getNewsList(this.page)
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/journalism/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.newContainer{
  padding-top: 2.75rem;
}
.newsItem{
  width: 100%;
  max-height: 150px;
  display: flex;
  padding: 1.25rem 0;
  border-bottom: 1px solid #E0E0E0;
}
.newContent{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: .9375rem;
}
.newsItemTitle{
font-size: 1rem;
color: #121212;
line-height: 1.5rem;
overflow:hidden;
text-overflow:ellipsis;
display:-webkit-box;
-webkit-box-orient:vertical;
-webkit-line-clamp:2;
}
.newContent span{
font-size: .75rem;
color: #888888;
}
</style>
